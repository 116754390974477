import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import { Container, Stack } from '@fiverr-private/layout_components';
import SafeComponent from '../../../logged_out_homepage/shared/SafeComponent';
import { MAX_WIDTH_CONTAINER } from '../../../logged_out_homepage/utils/constants';
import { TrustedBy } from '../TrustedBy';
import { OmrBadge } from './OmrBadge';
import { HiscoxBadge } from './HiscoxBadge';
import { Header } from './Header';

import './style.scss';

const HeroBannerGerman = () => {
  const { locale } = getContext();

  const isDeLocale = locale === SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE;

  return (
    <Container
      className="hero-wrapper-de"
      position="relative"
      backgroundColor="green_1200"
      borderRadius="xl"
      height="auto"
      marginX="6"
      marginBottom="9"
      minHeight={{
        sm: '480px',
      }}
    >
      <Stack
        className={MAX_WIDTH_CONTAINER}
        direction="column"
        alignItems="center"
        justifyContent={{
          sm: 'spaceBetween',
        }}
      >
        <Header />
        {isDeLocale && (
          <Stack
            justifyContent="center"
            alignItems="center"
            direction={{
              xl: 'column',
              default: 'row',
            }}
            position={{
              xl: 'absolute',
              default: 'static',
            }}
            marginBottom="1"
            columnGap={{
              sm: '14',
              default: '8',
            }}
            rowGap="6"
            paddingBottom="10"
            top="35px"
            right="35px"
            left="auto"
            zIndex="2"
          >
            <HiscoxBadge />
            <OmrBadge />
          </Stack>
        )}
        <TrustedBy />
      </Stack>
    </Container>
  );
};

export default SafeComponent(HeroBannerGerman);

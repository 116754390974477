import React from 'react';
import { Image } from '@fiverr-private/media';

export const OmrBadge = () => (
  <Image
    src="https://fiverr-res.cloudinary.com/image/upload/v1/attachments/generic_asset/asset/dc85290be3ad9af4029210bce10605d8-1712052764324/omg-badge-2024-q1.svg"
    alt="OMR badge"
    display="block"
    height={{
      default: '110px',
      lg: '130px',
    }}
  />
);

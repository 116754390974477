import React from 'react';
import { Image } from '@fiverr-private/media';

export const HiscoxBadge = () => (
  <Image
    src="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/aaea89789611569b5c9eeb626535cb95-1739871904811/HISCOX.png"
    alt="HISCOX badge"
    display="block"
    height="90px"
  />
);

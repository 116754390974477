import React from 'react';
import { bool } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import { lazyImport } from '../../../apps/logged_out_homepage/utils/lazyImport';
import HeroBannerGerman from '../../../apps/logged_out_homepage_de/components/HeroBannerGerman';
import { CategoryIcons } from '../../../apps/logged_out_homepage_de/components/CategoryIcons';
import Seo from '../../../apps/logged_out_homepage/shared/Seo';
import SubcategoryCarousel from '../../../apps/logged_out_homepage_de/components/SubcategoryCarousel';
import SellingProposition from '../../../apps/logged_out_homepage_de/components/SellingProposition';
import FrequentlyAskedQuestions from '../../../apps/logged_out_homepage_de/components/FrequentlyAskedQuestions';
import FiverrGuides from '../../../apps/logged_out_homepage_de/components/FiverrGuides';
import Categories from '../../../apps/logged_out_homepage_de/components/Categories';
import EntryWrapper from '../../../apps/logged_out_homepage_de/components/EntryWrapper';
import {
  germanCopyData,
  germanPictureData,
  germanImgSrc,
} from '../../../apps/logged_out_homepage_de/components/OldFiverrBusiness/config';
import TopPriorities from '../../../apps/logged_out_homepage_de/components/TopPriorities';
import { getSubCategories } from '../../../apps/logged_out_homepage_de/components/SubcategoryCarousel/config';
import { shouldLoadPersonalizedSection } from '../../../apps/logged_out_homepage_de/components/PersonalizedSectionWrapper/shouldLoad';
import { LAZY_COMPONENTS } from './constants';

const FiverrBusiness = lazyImport(
  LAZY_COMPONENTS.FIVERR_BUSINESS,
  () =>
    import(/* webpackChunkName: 'FiverrBusiness' */ '../../../apps/logged_out_homepage_de/components/OldFiverrBusiness')
);
const FiverrBusinessNew = lazyImport(
  LAZY_COMPONENTS.FIVERR_BUSINESS,
  () =>
    import(
      /* webpackChunkName: 'FiverrBusinessNew' */ '../../../apps/logged_out_homepage_de/components/NewFiverrBusiness'
    )
);
const FindExperts = lazyImport(
  LAZY_COMPONENTS.FIND_EXPERTS,
  () => import(/* webpackChunkName: 'FindExperts' */ '../../../apps/logged_out_homepage_de/components/FindExperts')
);
const Testimonials = lazyImport(
  LAZY_COMPONENTS.TESTIMONIALS,
  () =>
    import(/* webpackChunkName: 'GermanTestimonials' */ '../../../apps/logged_out_homepage_de/components/Testimonials')
);
const HowFiverrWorks = lazyImport(
  LAZY_COMPONENTS.HOW_FIVERR_WORKS,
  () =>
    import(/* webpackChunkName: 'HowFiverrWorks' */ '../../../apps/logged_out_homepage_de/components/HowFiverrWorks')
);
const PersonalizedSectionWrapper = lazyImport(
  LAZY_COMPONENTS.PERSONALIZED_SECTION_WRAPPER,
  () =>
    import(
      /* webpackChunkName: 'PersonalizedSectionWrapper' */ '../../../apps/logged_out_homepage_de/components/PersonalizedSectionWrapper'
    )
);

const LoggedOutHomepageClient = ({ userWasLoggedIn, displayBrandCampaign }) => {
  const { locale } = getContext();

  const subCategories = getSubCategories({ locale });

  const isDeLocale = locale === SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE;

  return (
    <EntryWrapper displayBrandCampaign={displayBrandCampaign} removeBottomSpace>
      <Seo />
      <HeroBannerGerman />
      <CategoryIcons />
      <div className="max-width-container">
        {shouldLoadPersonalizedSection({ userWasLoggedIn }) && <PersonalizedSectionWrapper />}
        <SubcategoryCarousel subCategories={subCategories} />
      </div>
      <SellingProposition />
      <HowFiverrWorks />
      <Testimonials />
      <Categories />
      {isDeLocale ? (
        <FiverrBusiness copyData={germanCopyData} imgSrc={germanImgSrc} pictureData={germanPictureData} />
      ) : (
        <FiverrBusinessNew />
      )}
      <div className="max-width-container">
        <FiverrGuides />
        <FrequentlyAskedQuestions />
        <FindExperts />
      </div>
      <TopPriorities />
    </EntryWrapper>
  );
};

LoggedOutHomepageClient.propTypes = {
  userWasLoggedIn: bool,
  displayBrandCampaign: bool,
};

export default LoggedOutHomepageClient;
